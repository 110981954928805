<template>
  <footer class="site-footer">
    <div class="container-fluid bg--gray">
      <div class="row justify-content-center py-4">
        <div class="col-12 col-lg-10">
          <div class="row align-items-center" >
            <div class="col-12 col-md-4 col-lg-3 text-center text-lg-left order-3 order-lg-1" v-if="itLoadsContact">
              <a :href="'tel:+'+optionPageData.phone" class="text-white no-link tx-ss">{{optionPageData.phone}}</a>
              <br />
              <a :href="'mailto:'+optionPageData.email" class="text-white no-link tx-ss">{{optionPageData.email}}</a>
              <div class="py-2 w-100 d-flex justify-content-center justify-content-lg-start">
                <a :href="optionPageData.instagram" class="text-white mr-3" target="blank">
                  <i class="fab fa-instagram"></i>
                </a>
                <a :href="optionPageData.linkedin" class="text-white mr-3" target="blank">
                  
                  <i class="fab fa-linkedin"></i>
                </a>
                <a :href="optionPageData.twitter" class="text-white mr-3" target="blank">
                  <i class="fab fa-twitter"></i>
                </a>
              </div>
            </div>
            <div class="col-12 col-md-4 col-lg-6 order-2">
              <app-links></app-links>
            </div>
            <div class="col-12 col-md-4 col-lg-3 d-flex justify-content-center justify-content-md-end order-1 order-lg-3">
              <LogoWhite />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid bg--black">
      <div class="row justify-content-center py-3">
        <div class="col-12 col-md-5 text-center text-white">
          <p class="d-inline-block px-2 tx-xs mb-0">© RBD {{ new Date().getFullYear() }}</p>
          <router-link to="/privacypolicy" class="text-white no-link px-2 tx-xs">Privacy Policy</router-link>
        </div>
        <div class="col-12 text-center text-white py-2">
          <a href="https://smldesign.com.au/" class="text-white no-link px-2 tx-xs text-uppercase">Created by SML</a>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import LogoWhite from "@/components/LogoWhite";
import AppLinks from "@/components/appLinksFooter";
export default {
  components: { LogoWhite, AppLinks },
  data(){
    return{
      optionPageData : Object,
      itLoadsContact: false
    }
  },
  methods: {
    fetchingContact() {
      this.$axios
        .get(this.$axios.defaults.baseURL + "acf/v3/options/site-options-page")
        .then(response => {
          this.optionPageData = response.data.acf;
          this.itLoadsContact = true;
          //console.log(this.optionPageData);
          
        })
        .catch(error => {
          console.log(error);
        });
    },
   },
  mounted(){
    this.fetchingContact();
    
  }
  
  
};
</script>

<style>
</style>