<template>
  <li class="nav-item mb-2 mb-md-4" v-show="true">
      <span class="number">0{{itemId}}</span>
      <span class="line-horizontal"></span>
      <router-link  :to="routeLink" no-prefetch @click.native="$store.dispatch('nav/toggleMenu')">{{name}}</router-link >
      <span class="line-angle"></span>
      <span class="angle"></span>
    </li>
</template>

<script>
export default {
    props:{
        name: String,
        routeLink: String,
        itemId: Number
    }
}
</script>

<style>
.fade-enter-active, .fade-leave-active {
  transition: opacity 5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>