<template>
  <router-link to="/" :class="{'logoWhite':isWhite}">
    <svg
      width="102"
      height="40"
      viewBox="0 0 102 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class="rbdLogo"
    >
      <path
        fill-rule="nonzero"
        d="M11.29 0L0 11.3132V40H2.16726L11.5083 30.7646L19.8605 40H22.5545L34 28.7055L26.0417 20.0057L31.9801 13.8972L31.9513 13.8779C33.3329 12.2952 34.0188 10.2129 33.9994 7.99493C33.9585 3.52509 30.8251 0 24.6389 0H11.29Z"
        fill="#000"
      />
      <path
        fill-rule="nonzero"
        d="M45.1659 0L34 11.3279V39.9582H44.4998C44.4998 39.9582 50.795 40.5461 53.976 37.311C57.1573 34.0756 64.5961 26.5096 64.5961 26.5096C66.0485 25.3494 67 23.6438 67 21.1791C67 17.861 64.9822 14.9525 61.4307 13.969C64.2557 13.0677 66.0314 10.7739 66.0314 7.8245C66.0314 2.37619 61.511 0 56.7492 0H45.1659Z"
        fill="#000"
      />
      <path
        fill-rule="nonzero"
        d="M78.1114 0C78.1114 0 67.2796 11.3103 67 11.3103V28.4405V29.45V39.99C67 39.99 77.637 40.0126 77.931 39.99C84.4999 39.485 87.3277 35.4327 87.3277 35.4327L98.443 24.323C98.443 24.323 98.4376 24.3087 98.4289 24.287C100.912 21.5109 102.079 17.7563 101.996 14.0214C101.834 6.9699 97.2349 0 87.6731 0H78.1114Z"
        fill="#000"
      />
    </svg>
  </router-link>
</template>
<script>
export default {
  props:{
    isWhite: Boolean
  }
}
</script>
<style>
.rbdLogo {
  animation: 1s appear;
  margin: auto;
}

@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
