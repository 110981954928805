<template>
  <div id="app">
    <Loading :start="load.val" />
    <router-view :key="$route.fullPath"></router-view>
  </div>
</template>

<script>
  import Loading from '@/components/Loading.vue'
  export default {
    components: {
      Loading
    },
    data() {
      return {
        load: {
          val: true
        }
      }
    },
    provide() {
      return {
        MyLoading: this.load
      }
    },
    head: {
      title: 'RBD',
      titleTemplate: '%s | Renovate Build Design',
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: 'Renovate Build Design'
        }
      ]
    }
  }
</script>
<style lang="scss">
  #app {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  html {
    font-size: 16px;
    word-spacing: 1px;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
  }

  *,
  *:before,
  *:after {
    box-sizing: border-box;
    margin: 0;
  }
</style>
