<template>
  <div class="container-fluid pt-3 pb-5" data-aos="fade-up">
    <div class="row">
      <div class="col-12 text-center">
        <h3>Next project</h3>

        <router-link
          class="h2 tx-c-primary no-link"
          tag="a"
          :to="{ name: 'Project', params: { id: item.id } }"
          >{{ item.title }}</router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      item: {
        id: Number,
        title: String
      }
    }
  }
</script>

<style></style>
