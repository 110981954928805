<template>
  <div class="container-fluid py-5">
    <div class="row justify-content-center">
      <div class="col-12 col-lg-10">
        <b-carousel
          id="carousel-1"
          v-model="slide"
          :interval="0"
          indicators
          background="#ECECEC"
          img-width="1024"
          img-height="480"
          @sliding-start="onSlideStart"
          @sliding-end="onSlideEnd"
          ref="projectGallerySlide"
        >
          <b-carousel-slide v-for="i in gallery" :key="i">
            <template v-slot:img>
              <img class="img-gallery" v-lazy="i" alt="image slot" />
            </template>
          </b-carousel-slide>
        </b-carousel>
        <div class="nav-slider">
          <button class="arrow arrow--left" @click="prev()">
            <b-icon icon="arrow-left-short"></b-icon>
          </button>
          <button class="arrow arrow--right" @click="next()">
            <b-icon icon="arrow-right-short"></b-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "@/plugins/vue-lazyload";
export default {
  props: {
    gallery: Array
  },
  data() {
    return {
      slide: 0,
      sliding: null
    };
  },
  methods: {
    onSlideStart(slide) {
      this.sliding = true;
    },
    onSlideEnd(slide) {
      this.sliding = false;
    },
    prev() {
      this.$refs.projectGallerySlide.prev();
    },
    next() {
      this.$refs.projectGallerySlide.next();
    }
  }
};
</script>

<style>
</style>