<template>
  <header class="site-header" ref="myHeader" v-bind:class="{ scrolled: isScrolled, 'menuOpen':toggleIcon() }">
    <div class="brand" @click="closeMenu()">
      <logo :isWhite="colorLogo"/>
    </div>
    <div class="btn-container">
      <div class="btn-menu" @click="openMenu()">
        <button class="hamburger hamburger--spin" type="button" :class="{'js-hamburger is-active':toggleIcon(), 'white-icon':darkBg}">
          <span class="hamburger-box">
            <span class="hamburger-inner"></span>
          </span>
        </button>
      </div>
    </div>
    <div class="space"></div>
  </header>
</template>

<script>
import Logo from "@/components/Logo.vue";
import windowScrollPosition from "@/plugins/windowScrollPosition";

export default {
  components: {
    Logo
  },
  props: {
    darkBg: Boolean,
    colorLogo: Boolean
  },
  created() {
    if (!this.$isServer) {
      window.addEventListener("scroll", this.handleScroll);
    }
  },
  destroyed() {
    // window.removeEventListener("scroll", this.handleScroll);
  },
  mixins: [windowScrollPosition("position")],
  data() {
    return {
      isScrolled: false,
    };
  },
  methods: {
    openMenu(){
      //store.dispatch('nav/toggleMenu');
      this.$store.dispatch('nav/toggleMenu');
    },
    closeMenu() {
      if (this.$store.getters["nav/toggleMenu"]) {
        this.$store.dispatch("nav/closeMenu");
      }
    },
    handleScroll(event) {
      // Any code to be executed when the window is scrolled
      if (this.position[1] > 120) {
        this.isScrolled = true;
      } else {
        this.isScrolled = false;
      }
    },
    toggleIcon() {
      return this.$store.getters["nav/toggleMenu"];
    }
  },
  mounted() {
    let headHeight = this.$refs.myHeader.clientHeight;
  }
};
</script>

<style>
</style>