import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

const moduleNav = {
  namespaced: true,
  state: {
    toggleMenu: false
  },
  mutations: {
    TOGGLE_MENU(state) {
      state.toggleMenu = !state.toggleMenu
    },
    CLOSE_MENU(state) {
      state.toggleMenu = false;
    }
  },
  getters: {
    toggleMenu: state => state.toggleMenu,
  },
  actions: {
    toggleMenu({ commit }) {
      commit('TOGGLE_MENU')
    },

    closeMenu({ commit }) {
      commit('CLOSE_MENU')
    }
  }
}

const moduleOpts = {
  namespaced: true,
  state: {
    dataopts: ''
  },
  mutations: {
    SET_DATA(state, theData) {
      state.dataopts = theData
    }
  },
  getters: {
    getDataOptions: state => state.dataopts,
  },
  actions: {
    getDataOptions({ commit }) {
      let data = Object;
      axios.get("https://rbd.com.au/wp/wp-json/acf/v3/options/site-options-page")
        .then(response => {
          data = response.data;
          //console.log(data);
        })
        .catch(error => {
          console.log(error);
        });
      commit('SET_DATA', data)
    }
  }
}

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    nav: moduleNav,
    opts: moduleOpts
  }
})
