<template>
  <div class="container-fluid py-5 py-md-3">
    <div class="row justify-content-center">
      <div class="col-12 col-lg-10" v-if="featureContent">
        <div class="one-col" v-if="layOutSlector(featureContent.acf_fc_layout)"  data-aos="fade-up">
          <div class="crop-img">
          <img :src="featureContent.img_1" alt='RBD' />
          </div>
          <p class="mt-3 mx-auto">{{featureContent.text_1}}</p>
        </div>
        <div class="row two-col" v-else>
          <div class="col-12 col-lg-6 pt-2"  data-aos="fade-right">
            <div class="crop-img crop-img--left">
            <img :src="featureContent.img_1" alt='RBD' />
            </div>
            <p class="mt-3">{{featureContent.text_1}}</p>
          </div>
          <div class="col-12 col-lg-6 pt-2" data-aos="fade-left">
            <p class="mb-3">{{featureContent.text_2}}</p>
            <div class="crop-img crop-img--right">
            <img :src="featureContent.img_2" alt='RBD' />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    featureContent: {
      acf_fc_layout: ""
    }
  },
  methods: {
    layOutSlector(layoutType) {
      if (layoutType == "one_col") {
        return true;
      } else {
        return false;
      }
    }
  }
};
</script>

<style>
</style>