<template>
  <div class="nav-container">
    <transition name="slide-side">
      <div v-if="toggleSidebar" class="menu-container">
        <div class="container-fluid">
          <div class="row mt-5 mt-lg-menu">
            <div class="col-12 offset-xl-1 col-xl-5 mt-5">
              <h1>Menu</h1>
            </div>
            <div class="col-12 col-xl-5 mt-3 mt-md-5 ">
              <app-links></app-links>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import AppLinks from "@/components/appLinks";

export default {
  components: {
    AppLinks
  },
  computed: {
    toggleSidebar() {
      return this.$store.getters["nav/toggleMenu"];
    }
  }
  
};
</script>

<style scoped>
.slide-side-enter-active,
.slide-side-leave-active {
  transition: all 0.3s cubic-bezier(.74,.05,.79,.66);
  opacity: 1;
}
.slide-side-enter,
.slide-side-leave-to {
  transform: translateY(-100%);
  opacity: 0.5;
}
</style>