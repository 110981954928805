<template>
  <div class="LayoutDefault main">
    <TheHeader/>
    <TheNav/>
    <main class="LayoutDefault__main">
      <slot/>
    </main>
  </div>
</template>

<script>
import TheHeader from '@/components/TheHeader.vue'
import TheNav from '@/components/TheNav.vue'
export default {
  components:{
    TheHeader, TheNav, 
  },
  
}
</script>