<template>
    <transition-group name="list" tag="ul" class="nav-list">
    <nav-item v-for="item in items" :key='item.itemId' :itemId="item.itemId" :name="item.name" :routeLink="item.routeLink"></nav-item>
    </transition-group>
</template>

<script>
import NavItem from '@/components/nav/navItem'
export default {
  components:{
    NavItem
  },
  data() {
    return {
      items: [
        {
          itemId:1,
          name: "Home",
          routeLink: '/'
        },
        {
          itemId:2,
          name: "About",
          routeLink: '/about'
        },
        {
          itemId:3,
          name: "Services",
          routeLink: '/services'
        },
        {
          itemId:4,
          name: "Projects",
          routeLink: '/projects'
        },
        {
          itemId:5,
          name: "Contact",
          routeLink: '/contact'
        }
      ]
    };
  }
};
</script>

<style>
.list-enter-active, .list-leave-active {
  transition: all 0.3s ease-out;
}
.list-enter, .list-leave-to /* .list-leave-active below version 2.1.8 */ {
  transform: translateX(-100%);
}
</style>