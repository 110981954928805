<template>
  <div class="LayoutProject">
    <TheHeader :darkBg="true" :colorLogo="true"/>
    <TheNav/>
    <main class="LayoutProject__main">
      <slot/>
    </main>
    <TheFooter/>
  </div>
</template>

<script>
import TheHeader from '@/components/TheHeader.vue'
import TheNav from '@/components/TheNav.vue'
import TheFooter from '@/components/TheFooter.vue'
export default {
  components:{
    TheHeader, TheNav, TheFooter
  }
}
</script>