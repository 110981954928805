<template>
<layout-home>
   <section v-if="dataErrored">
      <p>We're sorry, we're not able to retrieve this information at the moment, please try back later</p>
    </section>
  <div v-else class="layout__home">
    <div class="container-fluid pt-5 pt-md-3 pt-lg-1 mt-lg-0">
      <div class="row">
        <div class="col-12 col-md-6 offset-xl-1 col-xl-3 mt-10">
          <vue-typed-js v-if="itLoads" :strings="rWords" :showCursor="false">
            <h1 class="typing tx-wh-6 pl-xl-3 title text-uppercase"></h1>
          </vue-typed-js>
          <vue-typed-js v-if="itLoads" :strings="bWords" :showCursor="false">
            <h1 class="typing tx-wh-6 pl-xl-3 title text-uppercase"></h1>
          </vue-typed-js>
          <vue-typed-js v-if="itLoads" :strings="dWords" :showCursor="false">
            <h1 class="typing tx-wh-6 pl-xl-3 title text-uppercase"></h1>
          </vue-typed-js>
        </div>
      </div>
    </div>
    <div class="shapes col-12 col-xl-7">
      <div class="container_shapes" v-if="itLoads">
        <img v-lazy="pageData.fimg_url" class="stack" alt="RBD" />
      </div>
    </div>
  </div>
</layout-home>
</template>

<script>
// @ is an alias to /src
import "@/plugins/vue-typed-js";
import "@/plugins/vue-lazyload";
import LayoutHome from '@/layouts/LayoutHome';
export default {
  inject:['MyLoading'],
  name: "Home",
  data() {
    return {
      pageData: Object,
      rWords: [""],
      bWords: [],
      dWords: [],
      itLoads: false,
      dataLoading: true,
      dataErrored: false,
    }
  },
  components: {
    LayoutHome
  },
  methods: {
    fetchingData() {
      this.$axios
        .get(this.$axios.defaults.baseURL + "wp/v2/pages/6")
        .then(response => {
          this.pageData = response.data;
          this.rWords = this.pageData.acf.r_word.map(({ text }) => text);
          this.bWords = this.pageData.acf.b_word.map(({ text }) => text);
          this.dWords = this.pageData.acf.d_word.map(({ text }) => text);
          this.itLoads = true;
          this.MyLoading.val = false;
        })
        .catch((error) => {
          console.log(error);
          this.dataErrored = true
        }).finally(() => this.dataLoading = false);
    }
  },
  head() {
    if(this.dataLoading == false) {
      const metaArray = [];
      this.pageData.yoast_meta.map(ele => {
        metaArray.push({
          hid: ele.name ? ele.name : ele.property,
          name: ele.name ? ele.name : ele.property,
          content: ele.content,
        });
      });

      return {
        title: this.dataLoading ? 'RBD' : this.pageData._yoast_wpseo_title,
        meta: metaArray,
      }
    }
  },
  mounted() {
     this.fetchingData();
  },
  created() {
    this.MyLoading.val = true;
  }
};
</script>

<style lang="scss">
</style>
