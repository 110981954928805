<template>
  <layout-project >
    <section v-if="dataErrored">
      <p>We're sorry, we're not able to retrieve this information at the moment, please try back later</p>
    </section>
    <section v-else>
      <div v-if="itLoads" class="layout__projects layout__projects--single position-relative overflow-hidden">
      <div id="D" class="letter">
        <img :src="project.fimg_url" class="letter__img" />
      </div>

      <div class="container-fluid position-relative z-index-5 pb-5 pb-md-1 mb-4 mb-md-0" >
        <div class="row">
          <div class="col-12 offset-lg-1 col-lg-4 mt-10">
            <router-link :to="'/projects'" class="h5 text-white mb-3 d-block">Project</router-link>
            <span class="line-white"></span>
            <h1 class="text-white h2">{{ project.title.rendered }}</h1>
          </div>
        </div>
      </div>
      <div class="blank-space w-100 py-5 d-md-none"></div>
      <div class="container-fluid pt-5 pt-md-0">
        <div class="row pt-5 pt-md-0">
          <div
            class="col-12 col-md-8 offset-lg-1 col-lg-5 offset-xl-6 col-xl-4"
          >
            <div
              class="w-100 py-5 tx-difference-white"
              v-html="project.content.rendered"
            ></div>
          </div>
        </div>
      </div>
      <div class="w-100 py-5 d-none d-lg-block">
        <div class="py-5"></div>
      </div>
      <project-feature
        :featureContent="
          project.acf.feature_content ? project.acf.feature_content[0] : ''
        "
      ></project-feature>
      <project-gallery
        :gallery="project.acf.gallery ? project.acf.gallery : imgsGallerY"
      ></project-gallery>
      <project-next :item="next_project"></project-next>
    </div>
    </section>
    
  </layout-project>
</template>

<script>
  // @ is an alias to /src
  import ProjectFeature from '@/components/projects/projectFeature'
  import ProjectNext from '@/components/projects/projectNext'
  import ProjectGallery from '@/components/projects/projectGallery'
  import LayoutProject from '@/layouts/LayoutProject'
  import '@/plugins/vue-lazyload'
  export default {
    inject: ['MyLoading'],
    data() {
      return {
        id: this.$route.params.id,
        project: {
          content: {
            rendered: ''
          },
          title: {
            rendered: 'Project'
          },
          fimg_url: '',
          acf: {
            feature_content: [],
            gallery: []
          }
        },
        imgsGallerY: [],
        next_project: {},
        allprojects: null,
        allprojectsLoad: false,
        itLoads: false,
        dataLoading: true,
      dataErrored: false,
      }
    },
      head() {
    if(this.dataLoading == false) {
      const metaArray = [];
      this.project.yoast_meta.map(ele => {
        metaArray.push({
          hid: ele.name ? ele.name : ele.property,
          name: ele.name ? ele.name : ele.property,
          content: ele.content,
        });
      });
      return {
        title: this.dataLoading ? 'RBD' : this.project.yoast_title,
        meta: metaArray,
      }
    }
  },
    components: {
      LayoutProject,
      ProjectFeature,
      ProjectGallery,
      ProjectNext
    },
    methods: {
      fetchingData() {
        this.$axios
          .get(
            this.$axios.defaults.baseURL +
              'wp/v2/project/' +
              this.$route.params.id
          )
          .then((response) => {
            this.project = response.data
            this.itLoads = true
            this.MyLoading.val = false
          })
         .catch((error) => {
          console.log(error);
          this.dataErrored = true
        }).finally(() => this.dataLoading = false);
      },
      fetchingProjects() {
        this.$axios
          .get(this.$axios.defaults.baseURL + 'wp/v2/project/')
          .then((response) => {
            const { id } = this

            const projects = response.data
            const projectsIDs = projects.map((project) => project.id)
            const currentIndexProject = projectsIDs.indexOf(parseInt(id))

            const nextProjectToShow = this.getNextProjectToRender(
              currentIndexProject,
              parseInt(id),
              projectsIDs
            )

            const isTheNextProject = projects.find(
              (project) => project.id === nextProjectToShow
            )

            this.next_project = {
              id: isTheNextProject.id,
              title: isTheNextProject.title.rendered
            }
          })
          .catch((error) => {
            console.log(error)
          })
      },
      getNextProjectToRender(currentlyIndexId, currentlyProjectId, projects) {
        const totalProjects = projects.length
        const nextProject = currentlyIndexId + 1

        return nextProject >= totalProjects
          ? projects[0]
          : projects[nextProject]
      }
    },
    mounted() {
      this.fetchingData()
      this.fetchingProjects()
    },
    created() {
      this.MyLoading.val = true
      this.$store.dispatch('opts/getDataOptions')
      // this.$store.getters["opts/getDataOptions"];
    }
  }
</script>
