<template>
  <ul class="nav-list d-flex flex-column flex-lg-row py-3">
    <li class="nav-item text-center my-2">
      <router-link to="/">Home</router-link>
    </li>
    <li class="nav-item text-center my-2">
      <router-link to="/about">About</router-link>
    </li>
    <li class="nav-item text-center my-2">
      <router-link to="/services">Services</router-link>
    </li>
    <li class="nav-item text-center my-2">
      <router-link to="/projects">Projects</router-link>
    </li>
    <li class="nav-item text-center my-2">
      <router-link to="/contact">Contact</router-link>
    </li>
  </ul>
</template>

<script>
export default {};
</script>

<style>

</style>